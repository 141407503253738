import { alphabet } from './variables';
import { IAlphabetType } from '@/interfaces/alphabet.interface';

/* Author fullname */
const getAuthorFullName = (authorFirstName: string, authorMiddleName: string, authorLastName: string): string => {
  let authorName = '';
  if (!!authorFirstName && authorFirstName.length > 0) {
    authorName = authorName.concat(authorFirstName);
  }
  if (!!authorMiddleName && authorMiddleName.length > 0) {
    authorName = authorName.concat(` ${authorMiddleName}`);
  }
  if (!!authorLastName && authorLastName.length > 0) {
    authorName = authorName.concat(` ${authorLastName}`);
  }
  //Fix
  if (authorFirstName === authorLastName && authorMiddleName !== null) {
    return `${authorFirstName} ${authorMiddleName}`;
  }
  if (authorFirstName === authorLastName) {
    return authorFirstName;
  }
  return authorName;
};

/* Author shortname */
const getAuthorShortName = (authorFirstName: string, authorLastName: string): string => {
  let authorName = '';
  if (!!authorLastName && authorLastName.length > 0) {
    authorName = authorName.concat(authorLastName);
  }
  if (!!authorFirstName && authorFirstName.length > 0) {
    authorName = authorName.concat(`, ${authorFirstName}`);
  }
  return authorName;
};

/*Get page numbers for static paths */
const getPagesNumbers = (pages: number) => {
  const pathNumbers = [];

  for (let i = 1; i <= pages; i++) {
    pathNumbers.push({ pageNumber: `${i}` });
  }
  return {
    pathNumbers,
  };
};

const getTruncatedTxt = (txt: string, size: number) => {
  if (txt === null) {
    return '';
  }
  return txt.length > size ? `${txt.slice(0, size)}…` : txt;
};

const getPagesNumbersPaths = (pageLength: number) => {
  return Array.from({ length: pageLength }, (_, index) => ({
    params: {
      pageNumber: (index + 1).toString(),
    },
  }));
};

const arrayRange = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1 }, (value, index) => String(start + index * step));

const getCurrentYear = () => {
  let today = new Date();
  return today.getFullYear();
};

const getPlainSearchWord = (alias: string) => (alias.includes('+') ? alias.split('+').join(' ') : alias);

const getNoun = (number: number, one: string, two: string, five: string): string => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
};

/* Language Helper */
const getLanguage = (lang: string) => {
  type langsObject = {
    [wors: string]: string;
  };

  const langs: langsObject = {
    ru: 'Русский',
    en: 'Английский',
    ua: 'Украинский',
    et: 'Эстонский',
    de: 'Немецкий',
    fr: 'Французский',
    lv: 'Латвия',
    lt: 'Литва',
    uk: 'Украинский',
    kk: 'Казахский',
    it: 'Итальянский',
    fi: 'Финский',
    cs: 'Чешский',
    unknown: 'Неизвестно',
  };
  return langs[lang.toLowerCase() as keyof langsObject] ?? lang;
};

const getAlphabet = (letter: string) => {
  return alphabet[letter.toLowerCase() as keyof IAlphabetType] ?? letter;
};

const getFirstLetterCaps = (str: string) => {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
};

const getAuthorNameAbbreviation = (name: string) => (name?.length > 0 ? ` ${name[0].toUpperCase()}.` : ``);

const hideImgOnError = (e: React.SyntheticEvent): void => {
  (e.target as HTMLImageElement).style.visibility = 'hidden';
};

const getShortTimeString = (str: string) => {
  if (str === null) {
    return;
  }
  if (str.includes('минут')) {
    str = str.replace(/минута|минуты|минут/gi, 'мин.');
  }
  if (str.includes('секунд')) {
    str = str.replace(/секунда|секунды|секунд/gi, 'cек.');
  }
  return str;
};

export {
  getAuthorFullName,
  getAuthorShortName,
  getPagesNumbers,
  getTruncatedTxt,
  arrayRange,
  getPagesNumbersPaths,
  getCurrentYear,
  getPlainSearchWord,
  getNoun,
  getLanguage,
  getFirstLetterCaps,
  getAlphabet,
  getAuthorNameAbbreviation,
  hideImgOnError,
  getShortTimeString,
};
